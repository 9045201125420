import {type ReactNode, useEffect, useRef} from 'react';

import {withSentryErrorBoundary} from 'app/providers';
import {FetchWorkerManager} from 'components/Ad/helpers';
import {getDefaultAdConfig, meteEnvConfig} from 'config';
import {useAdStrategyContext, useAdUnitConfigContext, useAppContext} from 'context';
import {AdTypes} from 'features/adoppler';
import {
  getAppInfoBodyRequest,
  getDeviceInfoBodyRequest, getExtRequestBody,
  getImpressionByType,
  getRegularUrl, getAcrUrl, getUserRequestBody, generateWorkerScope,
} from 'shared/api/ad/adoppler/helpers';
import {getAdProxyUrl, getAdProxyArcUrl} from 'shared/api/adProxy-service';
import {getIpAddresses} from 'shared/api/ip-address/lib';
import {CountdownTracker} from 'shared/components';
import {WORKER_JOB_MAKE_CALL, WORKER_REGISTER_CUSTOM_AD_POD} from 'shared/constants';
import {getCacheConfig, shouldWorkerRunManually, subscribe, unsubscribe} from 'shared/utils';
import {acrService} from 'shared/utils/acr-service';
import {globalStorage} from 'shared/utils/globalStorage';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';
import {logger as baseLogger} from 'shared/utils/logger';
import {generateNonce} from 'shared/utils/pal';
import permutationService from 'shared/utils/permutation-service';

import type {DeviceInfo, WorkerConfig} from 'types';

import {AdopplerAd} from './Adoppler';

import type {DeviceInfoBodyRequest, Impression} from 'features/adoppler';
import type {DeviceIpAddresses} from 'shared/utils';

const logger = baseLogger.child({tag: '[AD Component]'});

const VIDEO_TYPES = [AdTypes.AdPod, AdTypes.Video, AdTypes.MultiBid];

export const Ad = (): ReactNode | null => {
  const {adUnitConfig} = useAdUnitConfigContext();
  const {deviceProperties} = useAppContext();
  const workerManager = useRef<FetchWorkerManager | null>(null);
  const device = useRef<DeviceInfoBodyRequest | null>(null);
  const ipAddresses = useRef<DeviceIpAddresses | null>(null);
  const nonce = useRef<string | undefined>();

  const {app} = getAppInfoBodyRequest();
  const defaultConfig = getDefaultAdConfig();
  const {startLoop, stopLoop, googleCanBePlayed} = useAdStrategyContext();

  const availableTypes = adUnitConfig?.providers?.gabriel?.adoppler.adTypes ?? meteEnvConfig.ads.types;
  const fetchInterval = adUnitConfig?.providers?.gabriel?.adoppler.config.fetchInterval;
  const eagerLoading = adUnitConfig?.providers?.gabriel?.googleTagManager?.eagerLoading
   ?? meteEnvConfig.ads.gpt.eagerLoading;
  const isEveFullscreenTemplate = adUnitConfig?.providers.gabriel?.adoppler.template === 'eve-screen-fullscreen';

  const isDongleAvailable =
    !adUnitConfig?.features?.skipDeeplink?.enable && permutationService.isDongleAvailable();

  const gDisplaySettings = {
    googleCanBePlayed,
    eagerLoading,
  };

  const adServer = LaunchDarklyService.getFlag('active-ad-server', 'elemental');
  const adamRequestStrategy = LaunchDarklyService.getFlag('adam-video-ad-request-strategy', 'cache');

  /**
   * Register custom worker to support dynamic AdPod
   * @param {Event} event
   */
  const registerWorkerAdPod = (event: Event) => {
    const customDuration = (event as CustomEvent).detail.duration;

    const cacheConfig = getCacheConfig(AdTypes.AdPod, adUnitConfig?.providers?.gabriel?.adoppler?.cache,
      defaultConfig.providers.gabriel.adoppler.cache);

    const url = adServer === 'ad-proxy'
        ? getAdProxyUrl('elemental', meteEnvConfig.ads.adUnit)
        : getRegularUrl(adUnitConfig.providers.gabriel.adoppler);
    const config = {
      manual: shouldWorkerRunManually(adamRequestStrategy),
      url,
      scope: generateWorkerScope(AdTypes.AdPod, customDuration),
      adType: AdTypes.AdPod,
      cache: {
        key: `cache-${AdTypes.AdPod}-${customDuration}`.toLowerCase(),
        ...cacheConfig,
      } } as WorkerConfig;

    const params = {
      imp: getImpressionByType(AdTypes.AdPod) as Impression,
      app,
      device: device.current,
      user: getUserRequestBody(deviceProperties),
      regs: {
        coppa: deviceProperties?.coppa ? 1 : 0,
      },
      ext: getExtRequestBody(deviceProperties, isDongleAvailable, nonce.current as string),
    };

    if (params.imp?.length > 0 && params.imp[0].video) {
      params.imp[0].video.poddur = customDuration;
      params.imp[0].video.maxduration = customDuration;
    }

    workerManager.current?.attach(config, params);
  };

  /**
   * Register job which will intercept custom worker job to make XHR call
   * @param {Event} event
   */
  const registerWorkerMakeCallJob= (event: Event) => {
    const workerScope = (event as CustomEvent).detail.scope as string;
    workerManager.current?.request(workerScope);
  };

  useEffect(() => {
    if (deviceProperties && availableTypes && !workerManager.current) {
      // TODO: Move worker cache variables to config level
      // set necessary css rule
      document.body.style.setProperty('--ads-rotation', `${fetchInterval}ms`);

      subscribe(WORKER_REGISTER_CUSTOM_AD_POD, registerWorkerAdPod);
      subscribe(WORKER_JOB_MAKE_CALL, registerWorkerMakeCallJob);

      (async () => {
        const manager = await generateNonce(isEveFullscreenTemplate);
        globalStorage.nonceManager = manager;

        nonce.current = manager?.getNonce();
        logger.info('Google PAL Nonce string', nonce);

        ipAddresses.current = await getIpAddresses(deviceProperties.ip_address);
        device.current = getDeviceInfoBodyRequest(deviceProperties as DeviceInfo, ipAddresses.current);

        const url = adServer === 'ad-proxy'
            ? getAdProxyUrl('elemental', meteEnvConfig.ads.adUnit)
            : getRegularUrl(adUnitConfig.providers.gabriel.adoppler);

        const requestParams = {
          app,
          device: device.current,
          user: getUserRequestBody(deviceProperties),
          regs: {
            coppa: deviceProperties?.coppa ? 1 : 0,
          },
        };
        const arcUrl = adServer === 'ad-proxy'
            ? getAdProxyArcUrl('elemental')
            : getAcrUrl(adUnitConfig.providers.gabriel.adoppler);
        acrService.setRequestParams(arcUrl, requestParams);

        const workerData = availableTypes.map((adType) => {
          const cacheConfig = getCacheConfig(adType, adUnitConfig?.providers?.gabriel?.adoppler?.cache,
            defaultConfig.providers.gabriel.adoppler.cache);

          return {
            config: {
              manual: shouldWorkerRunManually(adamRequestStrategy, adType),
              url,
              scope: generateWorkerScope(adType),
              adType,
              cache: {
                key: `cache-${adType}`.toLowerCase(),
                ...cacheConfig,
              },
            } as WorkerConfig,
            params: {
              imp: getImpressionByType(adType),
              ...requestParams,
              ext: getExtRequestBody(deviceProperties, isDongleAvailable,
                VIDEO_TYPES.includes(adType)? nonce.current as string : ''),
            },
          };
        });
        logger.info('Run fetchWorker Manager', workerData);
        workerManager.current = (new FetchWorkerManager(workerData));

        startLoop(fetchInterval);
      })();
    }

    return () => {
      logger.info('Terminate workers');
      workerManager.current?.terminate();
      workerManager.current = null;

      unsubscribe(WORKER_REGISTER_CUSTOM_AD_POD, registerWorkerAdPod);
      unsubscribe(WORKER_JOB_MAKE_CALL, registerWorkerMakeCallJob);
      stopLoop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceProperties]);

  return (
    <div data-testid="AdopplerAdTestId">
      {__DEV__ && <CountdownTracker element={{title: '<Ad />', top: 0}}/>}
      {withSentryErrorBoundary(
        <AdopplerAd settings={gDisplaySettings} />,
        {
          key: 'ADOPPLER',
        })}
    </div>
  );
};
