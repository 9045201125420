import {AdTypes} from 'features/adoppler';

import type {AdopplerCache, AdopplerCacheItem} from 'types';

import type {FlagSet} from 'shared/utils/launch-darkly-service/types';

/**
 * Get cache params based on adType
 * @param {AdTypes} adType
 * @param {AdopplerCache | null} adUnitCacheConfig
 * @param {AdopplerCache} defaultCacheConfig
 * @return {AdopplerCacheItem}
 */
export const getCacheConfig = (adType: AdTypes, adUnitCacheConfig: AdopplerCache | null,
  defaultCacheConfig: AdopplerCache): AdopplerCacheItem => {
  const cacheKey = `adoppler-${adType.toString().toLowerCase()}`;
  let cacheConfig = defaultCacheConfig[cacheKey] ?? defaultCacheConfig['default'];
  if (adUnitCacheConfig && (adUnitCacheConfig['default'] || adUnitCacheConfig[cacheKey])) {
    cacheConfig = adUnitCacheConfig[cacheKey] ?? adUnitCacheConfig['default'];
  }

  return cacheConfig;
};

// eslint-disable-next-line valid-jsdoc
/**
 * Check that worker should run manual way
 * @param {FlagSet['adam-video-ad-request-strategy'] | null} ldFlag
 * @param {AdTypes} adType
 * @return {boolean}
 */
export const shouldWorkerRunManually =
  (ldFlag: FlagSet['adam-video-ad-request-strategy'] | null, adType: AdTypes = AdTypes.AdPod): boolean => {
    return ldFlag === 'request' && adType === AdTypes.AdPod;
  };
